
import { defineComponent } from "vue";

export default defineComponent({
  name: "SelectBox",
  props: {
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
  },
});
