
import { defineComponent, reactive, ref, watch } from "vue";
import BaseRoundRectButton from "@/components/atoms/BaseRoundRectButton.vue";
import TextField from "@/components/atoms/TextField.vue";
import Modal from "@/components/common_presentational/Modal.vue";
import SelectBox from "@/components/atoms/SelectBox.vue";
import { SignInInfoArgs } from "@/entities/sign_in";
import { LocalizationError } from "@/error/error";
import { CommunicationState } from "@/type";
export default defineComponent({
  name: "SignInModal",
  components: {
    BaseRoundRectButton,
    TextField,
    Modal,
    SelectBox,
  },
  props: {
    localizationError: {
      type: Object as () => LocalizationError | null,
      default: null,
      required: false,
    },
    communicationState: {
      type: Object as () => CommunicationState,
      default: 1,
      required: true,
    },
  },
  emits: {
    signIn: (request: SignInInfoArgs) => {
      if (request != null) return true;
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      password: "",
      phoneNumber: "",
      countryCode: "+81",
    });

    const countryCodeOptions = [
      { value: "+1", text: "アメリカ" },
      { value: "+1", text: "カナダ" },
      { value: "+31", text: "オランダ" },
      { value: "+33", text: "フランス" },
      { value: "+34", text: "スペイン" },
      { value: "+39", text: "イタリア" },
      { value: "+41", text: "スイス" },
      { value: "+44", text: "イギリス" },
      { value: "+48", text: "ポーランド" },
      { value: "+49", text: "ドイツ" },
      { value: "+52", text: "メキシコ" },
      { value: "+60", text: "マレーシア" },
      { value: "+61", text: "オーストラリア" },
      { value: "+62", text: "インドネシア" },
      { value: "+63", text: "フィリピン" },
      { value: "+65", text: "シンガポール" },
      { value: "+66", text: "タイ" },
      { value: "+81", text: "日本" },
      { value: "+82", text: "韓国" },
      { value: "+84", text: "ベトナム" },
      { value: "+90", text: "トルコ" },
      { value: "+852", text: "香港" },
      { value: "+855", text: "カンボジア" },
      { value: "+886", text: "台湾" },
    ];

    function emitSignIn() {
      emit("signIn", { phone_number: state.phoneNumber, password: state.password, country_code: state.countryCode } as SignInInfoArgs);
    }
    const isLoading = ref<boolean>(false);
    watch(
      () => props.communicationState,
      () => {
        if (props.communicationState === 2) {
          isLoading.value = true;
        } else {
          isLoading.value = false;
        }
      }
    );
    return {
      state,
      isLoading,
      emitSignIn,
      countryCodeOptions,
    };
  },
});
