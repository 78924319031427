import { AxiosInstance } from "axios";
import { Event } from "@/entities/event";
import SessionRepository from "@/repositories/local/session_repository";
import { RankingUserInfo } from "@/entities/ranking";
import Talent, { MainImage } from "@/entities/talent";
import EventRepository from "@/repositories/event_repository";
import { TalentResponse } from "@/infra/api/talent_repository";
import { Timestamp, toDate } from "./utils";

interface EventResponse {
  id: number;
  caption?: string;
  detail?: string;
  benefits_image_uri?: string;
  caution?: string;
  start_date: Timestamp;
  end_date: Timestamp;
  key_visual_image_uri?: string;
  entry_benefits?: string;
  event_benefits?: Array<BenefitsByRankResponse>;
}

interface GetEventResponse {
  event: EventResponse;
}

interface BenefitsByRankResponse {
  target_image_uri: string;
  content: string;
  note: string;
  image_uri: string;
  priority: number;
}

interface GetEventRankingResponse {
  ranking: {
    entrants?: Array<RankingResponse>;
  };
}

interface RankingResponse {
  influencer: {
    influencer: TalentResponse;
    points: number;
  };
}

export default class APIEventRepository implements EventRepository {
  client: AxiosInstance;
  sessionRepo: SessionRepository;

  constructor(client: AxiosInstance, sessionRepo: SessionRepository) {
    this.client = client;
    this.sessionRepo = sessionRepo;
  }

  // APIアドレスを直で叩くためbasehostを設定し切り替え
  private basehost = process.env.VUE_APP_BASEHOST;

  async getEvent(eventParameter: string): Promise<Event> {
    try {
      const res = await this.client.get<GetEventResponse>(`https://${this.basehost}/v1/events/${eventParameter}`);
      const d = res.data.event;
      return new Event({
        id: d.id,
        startDate: toDate(d.start_date),
        endDate: toDate(d.end_date),
      });
    } catch (e) {
      throw e;
    }
  }

  async getEventRanking(eventID: number): Promise<Array<RankingUserInfo>> {
    try {
      const res = await this.client.get<GetEventRankingResponse>(
        `https://${this.basehost}/v1/events/${eventID}/ranking`
      );
      const data = res.data;
      if (data.ranking.entrants == null) {
        return [];
      }
      return data.ranking.entrants.map(
        (r) =>
          new RankingUserInfo({
            influencer: {
              influencer: new Talent({
                displayName: r.influencer.influencer.display_name ?? "",
                genre: r.influencer.influencer.genre ?? [],
                images: r.influencer.influencer.images?.map((e) => new MainImage({ url: e.url ?? "", position: e.position })) ?? [],
                introduction: r.influencer.influencer.introduction ?? "",
                talentID: r.influencer.influencer.uuid ?? "",
                liveStatus: 0,
                eventDate: 0,
              }),
              points: r.influencer.points,
            },
          })
      );
    } catch (e) {
      throw e;
    }
  }
}
