
import { defineComponent } from "vue";
import Features from "@/components/container/top/FeaturesContainer.vue";
import Search from "@/components/common_presentational/Search.vue";
import TalkNowTemplate from "@/components/presentational/top/TalkNowOfTopScreen.vue";
import TalkFutureTemplate from "@/components/presentational/top/TalkFutureOfTopScreen.vue";
import AllTemplate from "@/components/presentational/top/AllOfTopScreen.vue";
import GetNowTalents from "@/components/container/GetNowTalentsContainer";
import GetFutureTalents from "@/components/container/GetFutureTalentsContainer";
import GetAllTalents from "@/components/container/GetAllTalentsContainer";
const TalkNow = GetNowTalents(TalkNowTemplate);
const TalkFuture = GetFutureTalents(TalkFutureTemplate);
const All = GetAllTalents(AllTemplate);

export default defineComponent({
  name: "TopScreen",
  components: {
    Features,
    Search,
    TalkNow,
    TalkFuture,
    All,
  },
});
