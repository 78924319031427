
import { defineComponent, PropType, reactive, watch, onMounted, computed } from "vue";
import LPRankingUser from "@/components/common_presentational/LPRankingUser.vue";
import RankingFirst from "@/components/common_presentational/rankings/RankingFirst.vue";
import RankingSecond from "@/components/common_presentational/rankings/RankingSecond.vue";
import RankingThird from "@/components/common_presentational/rankings/RankingThird.vue";
import { RankingUserInfo } from "@/entities/ranking";
import { Event } from "@/entities/event";
import { activate, fetchConfig, getRemoteConfig, getValue } from "firebase/remote-config";
import { FirebaseOptions, initializeApp } from "firebase/app";
import { useRepositories } from "@/infra/api/injects";
import Talent from "@/entities/talent";

export default defineComponent({
  name: "EventRankingWithFirebase",
  components: {
    LPRankingUser,
    RankingFirst,
    RankingSecond,
    RankingThird,
  },
  props: {
    rankingUsers: {
      type: Array as PropType<RankingUserInfo[]>,
      required: true,
    },
    event: {
      type: Object as PropType<Event>,
      required: true,
    },
    getRankingPath: {
      type: String as PropType<string>,
      required: true,
      default: "",
    },
    getEnableEventPath: {
      type: String as PropType<string>,
      required: true,
      default: "",
    },
    isPreEvent: {
      type: Boolean,
      default: false
    }, 
    showRankingCount: {
      type: Number,
      default: 10,
    }
  },
  setup(props) {
    const state = reactive({
      isLoading: true,
      date: "",
      rankingUsers: [] as Array<RankingUserInfo>,
      enableEvent: false,
      sortedRankingUsers: [] as Array<RankingUserInfo>,
      showRankingCount: 0,
    });

    watch(
      () => props.event,
      () => {
        const sd = props.event.startDate;
        const ed = props.event.endDate;

        const startDate = `${sd.getFullYear()}.${sd.getMonth() + 1}.${sd.getDate()}`;

        const hasDiffMoreAYear = sd.getFullYear() != ed.getFullYear();
        const endDate = `${hasDiffMoreAYear ? ed.getFullYear() + "." : ""}${ed.getMonth() + 1}.${ed.getDate()}`;

        state.date = `${startDate} ~ ${endDate}`;
      }
    );


    const title = computed(() => {
      if (props.isPreEvent) {
        return "プレランキング"
      } else {
        return "現在のランキング"
      }
    })


    const repo = useRepositories();
    onMounted(async () => {
      // firebaseのremote configを取得
      const firebaseConfig = {
        projectId: process.env.VUE_APP_FIRE_BASE_PROJECT_ID,
        apiKey: process.env.VUE_APP_FIRE_BASE_API_KEY,
        appId: process.env.VUE_APP_FIRE_BASE_APP_ID,
      } as FirebaseOptions;

      const app = initializeApp(firebaseConfig);
      const remoteConfig = getRemoteConfig(app);
      remoteConfig.defaultConfig = {
        welcome_message: "Welcome",
      };
      remoteConfig.settings.minimumFetchIntervalMillis = 0;
      await fetchConfig(remoteConfig);
      await activate(remoteConfig);

      // remote configからイベントランキングとランキングを表示するかどうかのフラグを取得
      const val = getValue(remoteConfig, props.getRankingPath).asString();
      state.enableEvent = getValue(remoteConfig, props.getEnableEventPath).asBoolean();

      const json = JSON.parse(val);
      const element = json["ranking"];

      if(state.enableEvent == true) {
        if(props.rankingUsers.length == 0) {
          for (let i = 0; i < element.length; i++) {
            if (element.length <= i) {
              state.rankingUsers.push(
                new RankingUserInfo({
                  influencer: {
                    influencer: new Talent({
                      displayName: "",
                      eventDate: 0,
                      genre: [],
                      images: [],
                      introduction: "",
                      talentID: "",
                      liveStatus: 0,
                    }),
                    points: 0,
                  },
                })
              );
            } else {
              await repo.value.TalentRepository.getTalentByID(element[i]["uuid"] as string).then((talent) => {
                state.rankingUsers.push(
                  new RankingUserInfo({
                    influencer: {
                      influencer: talent,
                      points: element[i]["point"],
                    }, 
                  })
                )
              });
            }
          }
          state.sortedRankingUsers = state.rankingUsers.sort((a, b) => {
            return b.influencer.points - a.influencer.points
          })
        } else {
          // 通話で獲得したスコアを持つランキングユーザーが存在する場合、remote configに入っているスコア数と獲得スコアを加算して表示する
          for (let i = 0; i < element.length; i++) {
            await repo.value.TalentRepository.getTalentByID(element[i]["uuid"] as string).then((talent) => {
            const filteredInfluencer = props.rankingUsers.filter(e => e.influencer.influencer.talentID == talent.talentID)[0]
              if(filteredInfluencer != null && filteredInfluencer.influencer.points != undefined) {
                state.rankingUsers.push(
                  new RankingUserInfo({
                    influencer: {
                      influencer: talent,
                      points: element[i]["point"] + filteredInfluencer.influencer.points,
                    }, 
                    
                  })
                );
              } else {
                state.rankingUsers.push(
                  new RankingUserInfo({
                    influencer: {
                      influencer: talent,
                      points: element[i]["point"],
                    }, 
                    
                  })
                );
              }
            });
          }
          // スコアが高い順にソートして表示する
          state.sortedRankingUsers = state.rankingUsers.sort((a, b) => {
            return b.influencer.points - a.influencer.points
          })
        }
      }
      // ランキングの表示数を変化させる
      // const hasPointInfluencersLength = state.sortedRankingUsers.filter(e => e.points > 0).length
      // if(hasPointInfluencersLength < 8) {
      //   state.showRankingCount = 5
      // } else {
      //   state.showRankingCount = 10
      // }
      state.isLoading = false;
    });
    return {
      state,
      title
    };
  },
});
