
import { defineComponent, h, reactive, onMounted } from "vue";
import { useRepositories } from "@/infra/api/injects";
import { useRoute } from "vue-router";
import TalentInfo from "@/components/presentational/talent/TalentInfo.vue";
import { FanMeetingAndReserved } from "@/entities/fan_meeting_and_reserved"
import Talent from "@/entities/talent";
import Fan from "@/entities/fan";
import { CommunicationState } from "@/type";
import { itemCodes } from "@/util/item_code";
import { useRouter } from "vue-router";


export default defineComponent({
  name: "TalentInfoContainer",
  setup() {
    const router = useRouter();

    const state = reactive({
      talent: {} as Talent,
      nowFanMeeting: undefined as FanMeetingAndReserved | undefined,
      fanMeeting: [] as FanMeetingAndReserved[],
      fan: undefined as Fan | undefined,
      balance: 0,
      communicationState: CommunicationState.noting as CommunicationState,
    });

    // injectしているメソッドの呼び出し
    const repositories = useRepositories()

    const talentID = useRoute().params.talent_id;

    // メソッド定義
    async function getTalentByID() {
      try {
        const talent = await repositories.value.TalentRepository.getTalentByID(talentID)
        if (talent.talentID === "a34eb6a3-e7be-45f8-983f-9599355930b1") {
          router.replace({ path: "/404" });
          return;
        } else {
          state.talent = talent;
        }
      } catch (e) {
        router.replace({ path: "/404" });
        console.log(e)
      }
    }


    // メソッド定義
    async function createReservation(fanMeetingId: number) {
      const fanMeetingAndReserved = state.fanMeeting.find((fm) => fm.fanMeeting.id == fanMeetingId)
      const itemCode = itemCodes.find((e) => e[0] === fanMeetingAndReserved?.fanMeeting.itemCode) ?? []
      const coinNum = itemCode[1] as number

      if (state.balance >= coinNum) {
        try {
          state.communicationState = CommunicationState.doing;
          await repositories.value.ReservationRepository.create(fanMeetingId)
        } catch (e) {
          console.log(e)
        } finally {
          state.communicationState = CommunicationState.noting;
          location.reload()
          alert("予約が完了しました。\n通話開始前にアプリで「マイク」と「カメラ」の許可を必ず行ってください。")
        }
      } else {
        alert("コイン残高が足りません")
      }
    }

    async function getFan(): Promise<void> {
      const fan = await repositories.value.FanRepository.getFan();
      state.fan = fan
    }

    async function getBalance(): Promise<void> {
      state.balance = await repositories.value.FanRepository.getBalance()
    }



    async function getScheduleByTalentID() {
      try {
        state.fanMeeting = await repositories.value.TalentRepository.getScheduleByTalentID(talentID)
        if (state.fanMeeting == undefined) {
          state.fanMeeting = [][1]
        }
      } catch (e) {
        // 強制的にundefinedを代入し、取得できなかった場合の表示分けを行う
        state.fanMeeting = [][1]
      }
    }
    async function getNowFanMeetingByTalentId() {
      const l = await repositories.value.TalentRepository.getNowFanMeetingByTalentId(talentID as string)
      state.nowFanMeeting = l
    }

    // メソッド呼び出し
    onMounted(async () => {
      await getTalentByID()
      await getScheduleByTalentID()
      await getNowFanMeetingByTalentId()
      await getFan()
      await getBalance()
    })

    return {
      state,
      repositories,
      createReservation,
    };
  },
  render() {
    return h(TalentInfo, {
      talent: this.state.talent,
      nowFanMeeting: this.state.nowFanMeeting,
      schedule: this.state.fanMeeting,
      fan: this.state.fan,
      balance: this.state.balance,
      communicationState: this.state.communicationState,
      onCreateReservation: (fanMeetingId: number) => this.createReservation(fanMeetingId),
    });
  },
});
