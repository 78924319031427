import { defineComponent, onMounted, h, ref } from "vue";
import { loadStripe, Stripe, StripeCardNumberElement, StripeError } from "@stripe/stripe-js";
import Payment from "@/components/presentational/Payment.vue";
import { useRepositories } from "@/infra/api/injects";
import { PurchaseRequest } from "@/repositories/wallet_manager_repository";
import { Product } from "@/entities/product";
import { CommunicationState, ResultState } from "@/type";
import { useRouter } from "vue-router";
import { InternalError } from "@/entities/errors";

const PaymentContainer = (WrappedComponent: any) =>
  defineComponent({
    name: "StripeContainer",
    components: {
      Payment,
    },
    setup() {
      const product = ref<Product>();
      const communicationState = ref<CommunicationState>(2);
      const stripe = ref<Stripe | null>(null);
      const PurchaseState = ref<ResultState>(0);
      const stripeErr = ref<StripeError | undefined>();
      const repo = useRepositories();
      const router = useRouter();

      const getProduct = (): Product => {
        try {
          return repo.value.LocalProductRepository.getProduct();
        } catch (e) {
          router.push({ name: "TopScreen" });
          throw e;
        }
      };

      onMounted(async () => {
        communicationState.value = 2;
        stripe.value = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
        try {
          product.value = getProduct();
        } catch (e) {
          if (e instanceof InternalError) {
            router.push({ name: "ServerErrorScreen" });
            return;
          }
        }
        communicationState.value = 1;
      });

      async function purchase(element: StripeCardNumberElement) {
        if (stripe.value == null || communicationState.value == 2) return;
        communicationState.value = 2;
        const result = await stripe.value.createToken(element);

        if (result.error != null) {
          if (result.error.type == "validation_error") {
            stripeErr.value = result.error;
            communicationState.value = 1;
            return;
          } else {
            PurchaseState.value = 2;
          }
        }

        try {
          if (result.token == undefined || product.value?.code == null) return;
          const request: PurchaseRequest = {
            token: result.token.id,
            code: product.value.code,
          };

          

          await repo.value.WalletManagerRepository.purchase(request);

          PurchaseState.value = 1;
        } catch (e) {
          PurchaseState.value = 2;
        } finally {
          communicationState.value = 1;
        }
      }
      return {
        purchase,
        product,
        stripe,
        communicationState,
        PurchaseState,
        stripeErr,
      };
    },
    render() {
      return h(WrappedComponent, {
        product: this.product,
        validationError: this.stripeErr,
        onPurchase: (element: StripeCardNumberElement) => this.purchase(element),
        stripe: this.stripe,
        communicationState: this.communicationState,
        resultState: this.PurchaseState,
      });
    },
  });
export default PaymentContainer;
