import { BaseError, InternalError } from "@/entities/errors";
import { Product } from "@/entities/product";
import SessionRepository from "@/repositories/local/session_repository";
import WalletManagerRepository, { PurchaseRequest } from "@/repositories/wallet_manager_repository";
import { AxiosInstance } from "axios";

interface GetProductsResponse {
  products: Array<ProductResponse>;
}

interface ProductResponse {
  code: string;
  amount: number;
  point: number;
}

export default class APIWalletManagerRepository implements WalletManagerRepository {
  client: AxiosInstance;
  sessionRepo: SessionRepository;
  constructor(client: AxiosInstance, sessionRepo: SessionRepository) {
    this.client = client;
    this.sessionRepo = sessionRepo;
  }
  private baseHost = process.env.VUE_APP_PAYMENT_HOST;
  async getProducts(): Promise<Array<Product>> {
    const res = await this.client.get<GetProductsResponse>(`${this.baseHost}/v1/products`, {});
    const products: Product[] = [];
    res.data.products.map((r) => {
      products.push(
        new Product({
          code: r.code,
          amount: r.amount,
          point: r.point,
        })
      );
    });
    return products;
  }
  async purchase(r: PurchaseRequest): Promise<void> {
    try {
      const auth = await this.sessionRepo.getAuthInfo();
      console.log("----- token: ", r.token);
      await this.client.post<void>(`${this.baseHost}/v1/payment`, {
        fan_uuid: auth.fan_uuid,
        token: r.token,
        code: r.code,
      });
    } catch (e) {
      throw e;
    }
  }
}

